import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
import menuApplication from "./layouts/menu-application";

// function loadView(view) {
//     return () => import(`./views/${view}.vue`);
// }
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function ($Core) {
    const router = new createRouter({
        routes: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: Home,
            },
            {
                path: "/Cong-Hoa-Don-Dien-Tu",
                name: "DanhSachKyHieu",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: loadViewIndex("Cong-Hoa-Don-Dien-Tu"),
            },
            {
                path: "/Cong-Hoa-Don-Dien-Tu/Them-Sua-Ky-Hieu",
                name: "Them-Sua-Ky-Hieu",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Cong-Hoa-Don-Dien-Tu/Them-Sua-Ky-Hieu"
                ),
            },

            {
                path: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                name: "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung"
                ),
            },
            {
                path: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don",
                name: "Cap-Nhat-Loai-Hoa-Don",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don"
                ),
            },
            {
                path: "/",
                redirect: "/home",
            },
            {
                path: "/recovery",
                redirect: "/home",
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/home",
            },
        ],
        history: createWebHistory(),
    });

    router.beforeEach((to, from, next) => {
        console.log("abcd", $Core);
        if (to.name === "login-form" && $Core.Auth.IsAuth) {
            next({ name: "home" });
        }

        if (!$Core.Auth.IsAuth) {
            $Core.Auth.Login();
            // next({
            //     name: "login-form",
            //     query: { redirect: to.fullPath }
            // });
        } else {
            next();
        }
    });
    return router;
}
