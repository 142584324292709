export default {
    namespaced: true,
    state: () => ({
        DanhSachLoaiHoaDon: [],
        DanhSachKyHieu: [],
        DanhSachLoaiHoaDonDoanhNghiepSuDung: [],
        DanhSachKieuHoaDon: [],
        DanhSachTuyen: [],
    }),

    mutations: {
        DanhSachLoaiHoaDon: (state, data) => {
            state.DanhSachLoaiHoaDon = data;
        },
        DanhSachKyHieu: (state, data) => {
            state.DanhSachKyHieu = data;
        },
        DanhSachLoaiHoaDonDoanhNghiepSuDung: (state, data) => {
            state.DanhSachLoaiHoaDonDoanhNghiepSuDung = data;
        },
        DanhSachKieuHoaDon: (state, data) => {
            state.DanhSachKieuHoaDon = data;
        },
        DanhSachTuyen: (state, data) => {
            state.DanhSachTuyen = data;
        },
    },
    actions: {
        async LayDanhSachLoaiHoaDon({ commit }) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachLoaiHoaDon")
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    commit("DanhSachLoaiHoaDon", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachTuyen({ commit }) {
            try {
                let resp = await this.$Core.Api.QuanLyThongTin(
                    this.$i18n.global.t("urlCHDDT.DanhSachTuyen")
                ).Get();

                let result = resp.Data.data;
                result.forEach((e) => {
                    e.TenTuyenMaTuyen = `${e.TenTuyen} (${e.MaTuyen})`;
                });
                result.unshift({
                    IdDnvtTuyen: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    TenTuyen: "Tất cả",
                    TenTuyenMaTuyen: "Tất cả",
                });
                if (resp.Data.errorCode === 0) {
                    commit("DanhSachTuyen", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachKieuHoaDon({ commit }) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachKieuHoaDon")
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    commit("DanhSachKieuHoaDon", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachLoaiHoaDonDoanhNghiepSuDung({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t(
                        "urlCHDDT.DanhSachLoaiHoaDonDoanhNghiepSuDung"
                    ),
                    data
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0 || resp.Data.errorCode === 1002) {
                    commit("DanhSachLoaiHoaDonDoanhNghiepSuDung", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachKyHieu"),
                    data
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0 || resp.Data.errorCode === 1002) {
                    commit("DanhSachKyHieu", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async ThemKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.ThemKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async SuaKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.SuaKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async XoaKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.XoaKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async CapNhatLoaiHoaDon({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.CapNhatLoaiHoaDon")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async XoaLoaiHoaDon({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.XoaLoaiHoaDon")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};
